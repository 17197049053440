import { Link } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'

import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'
import CaseStudyVideoCase from '~/views/CaseStudies/components/CaseStudyVideo'

import * as containerStyles from './Caker.module.scss'
import useCakerHQStaticQuery from './useCakerHQStaticQuery'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

const previewVariaTitle = 'Custom SaaS Platform for Real Estate'
const previewImpactTitle = 'Fitness Progressive Web Application'

const quotes = {
  client: {
    name: 'Lisa',
    position: 'Founder of CakerHQ',
  },
  pm: {
    name: 'Arpi',
    position: 'PM Team Lead at Codica',
  },
}
const CaseStudiesCakerHQ = () => {
  const query = useCakerHQStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )
  const solutionDeliveredSeven = getImage(
    fileToImageLikeData(query.solutionDeliveredSeven),
  )
  const videoCaseImage = getImage(fileToImageLikeData(query.videoCaseImage))

  return (
    <MainLayout isTransparent scrollTop>
      <div className={containerStyles.caseStudyHead}>
        <CaseStudyHead
          title="Custom SaaS platform for CakerHQ"
          subtitle="Learn how Codica created an eye-catching, intuitive, and secure SaaS platform for a bakery business. It allows users to book desserts in a pair of clicks using only secure payments."
          maxWidthSubtitle="420px"
          image={bannerCase}
          classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
          imageAlt="The custom bakery website developed by the Codica team"
          imageTitle="The custom bakery website developed by Codica"
          domain="Bakery"
          location="Australia"
          timeline="February 2022 - July 2022"
          services={
            <>
              <Link to="/services/product-discovery/" key="productKey">
                Product discovery
              </Link>
              , Front-end, Back-end,
              <Link
                to="/services/ui-ux-design/"
                className="ml5"
                key="designKey"
              >
                UX/UI design services
              </Link>
              ,
              <Link
                to="/services/saas-development/"
                className="ml5"
                key="saasDevKey"
              >
                SaaS development
              </Link>
              ,
              <Link to="/services/devops/" className="ml5" key="devopsDevKey">
                DevOps services
              </Link>
            </>
          }
          team="4 Software Developers, 1 UX/UI Designer, 1 QA Engineer, 1 DevOps Engineer, 1 Project Manager"
          technologies="Ruby on Rails, ReactJS, NextJS, Google Cloud, Twilio, Sendgrid, Stripe, AWS, Sentry"
          isQuote
        />

        <CaseStudyQuote
          quote={[
            {
              text: '“In my previous business within the cake industry in Australia, I saw first hand the frustrations that the baking business owners faced in the running of their businesses. Some business owners were spending more time doing administration work than doing the creative work of baking and decorating! I surveyed and researched for a few years before deciding on developing CakerHQ in its current format to help these small business owners.',
            },
            {
              text: 'After reviewing some of Codica’s other projects and meeting with the CEO and Business Development Manager, it was obvious that the team had the expertise to develop this software. Codica’s experts understood from the outset exactly what I was trying to achieve and worked with me to bring this vision to life.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/clients/client-caker.png"
              alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
              title={`${quotes.client.name}, ${quotes.client.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.client.name}
          position={quotes.client.position}
        />
      </div>

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudy__bgDark}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTextLeft={[
          'CakerHQ is an Australian start-up, a custom SaaS platform for bakery businesses intended to optimize business administration and make it easy for customers to find and book desserts for their events.',
          <div className="mb-3" key="bgTextLeft" />,
          'The founder, Lisa, worked in accounting and finance management before moving into retailing products for cake makers. She noticed that customers often need help finding a baker in their location. So, the idea for a SaaS-based platform, CakerHQ, as a problem solution arose.',
        ]}
        bgTextRight={[
          'We developed this website for the bakery businesses from scratch. As a result, the client received a simple in usage and appealing solution that works its best on both desktop and mobile devices. By the way, at the discovery stage, our experts found out that we need to focus on mobile optimization to increase user traffic. So, we put a lot of emphasis on developing the mobile app interface.',
          <div className="mb-3" key="bgTextRight" />,
          'Also, we implemented a secure payment system that ensures safe payments and increases customer confidence in the brand.',
        ]}
        sectionBusiness={[
          {
            label:
              'Effectively replace the baking business’s current quoting, order-taking, and payment processing systems.',
          },
          {
            label:
              'Make the SaaS platform for bakery businesses user-friendly and attractive, with design emphasizing the brand’s image.',
          },
          {
            label:
              'Optimize the custom bakery website for both desktop and mobile devices.',
          },
          {
            label:
              'Integrate Google Maps and Google Places API services to help customers find cake makers nearby.',
          },
          {
            label:
              'Create a convenient messaging system to simplify communication between cake makers and customers.',
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Developed a clear and simple quote system to send the cake request to the baker and a convenient messaging system. These systems greatly simplify the interaction between cake makers and buyers.',
          },
          {
            label:
              'Integrated the Google Places and Google Maps APIs. These services allow customers to see more cake makers and the possible delivery radius. So they have all the information to make a deliberate choice.',
          },
          {
            label:
              'Created convenient search filters. So the customers can find exactly what they are looking for very quickly.',
          },
          {
            label:
              'Optimized the SaaS-based platform for mobile devices. So, the bakery solution looks amazing on both desktop and smaller mobile screens.',
          },
          {
            label:
              'Implemented a modern and secure payment system, Stripe. Thus, the users can be sure their payments on this custom bakery website are protected.',
          },
          {
            label:
              'Created a neat and appealing bakery website design, making it user-friendly, which impacts users’ involvement.',
          },
        ]}
      />

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          quote={[
            {
              text: '“Our team conducted comprehensive work to develop this SaaS solution from scratch. We started with project idea discovery and the prototypes’ creation and finished with the successful delivery of the website app. All the work was done with the client’s desired business outcomes in mind. Now, a client owns a scalable, appealing, and secure solution designed with the latest tech practices in mind.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/PM-Arpi.png"
              alt={`${quotes.pm.name}, ${quotes.pm.position} at Codica`}
              title={`${quotes.pm.name}, ${quotes.pm.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.pm.name}
          position={quotes.pm.position}
        />
      </div>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDark}
        title="Do you need a reliable team to build a custom web solution?"
        description="We apply best practices to help you save costs and launch quickly."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          image={solutionDeliveredOne}
          title="Enhanced UX and UI"
          description={[
            'We designed this SaaS solution for bakery businesses so it is clear to visitors what kind of product they are offering to purchase. We used neat and high-quality photos of cakes that create a feeling of a cozy bakery from which comes the aroma of baking.',
            <div className="mb-2" key="descr" />,
            'We developed the UX (user experience) and UI (user interface) bakery website design using the latest design practices. As a result, the SaaS platform for bakery businesses is user-friendly and engaging. So, it drives traffic and sales.',
          ]}
          alt="UX and UI design for the custom bakery website | Codica"
          imageTitle="UI and UX design for the custom bakery website | Codica"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="Improved search filters"
          description={[
            'In terms of usability, we have created convenient search filters that refine the query as users enter new parameters of the desired confectionery product. So visitors can find exactly what they are looking for quickly and easily.',
            <div className="mb-2" key="descr" />,
            'With the new search filters, we have shortened the user’s path from the idea of buying to paying for a product on this SaaS platform for bakery businesses. Thus, we have increased customer loyalty.',
          ]}
          alt="Enhanced search filters for the custom bakery website | Codica"
          imageTitle="Improved search filters for the custom bakery website | Codica"
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="Secure payment system"
          description={[
            'When choosing a payment system, we select Stripe in most cases. Besides, it is also the choice of Amazon, Shopify, Zoom, and many successful web solutions.',
            <div className="mb-2" key="descr" />,
            'Thanks to the Stripe implementation, we secured users’ payments inside this SaaS platform for bakery businesses. Also, Stripe enables quick and seamless transactions within the platform. Thus, we increased people’s credibility in the CakerHQ brand.',
            <div className="mb-2" key="descrNext" />,
            'With Stripe, we also provided different payment options for customers depending on the event date. For example, users can pay for orders in full amount at once or partly. Also, if the customer cancels the order beforehand, they can get a full or partial refund.',
          ]}
          alt="Secure payment system for the custom bakery website | Codica"
          imageTitle="Payment system for the custom bakery website | Codica"
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredFour}
          title="Convenient calendar for customers and bakers"
          description={[
            'We added a convenient calendar to help customers choose the date they want to place their order. Also, using this calendar, bakers can modify their availability schedule. There, they can select the date when they have time to complete the customers’ orders.',
            <div className="mb-2" key="descr" />,
            'In short, this feature lets users plan a convenient time and date according to their needs. With this calendar, we made the custom SaaS platform for bakery businesses more user-oriented.',
          ]}
          alt="Convenient calendar for the custom bakery website | Codica"
          imageTitle="Calendar for the custom bakery website | Codica"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="Google Maps and Google Places API"
          description="The Google Maps API is used for putting maps on the website. The Google Places API is intended for looking up what point is at a given address. With the help of these APIs, visitors can easily find cake makers in a certain location."
          alt="Enhanced online maps for the custom bakery website | Codica"
          imageTitle="Online maps for the custom bakery website | Codica"
        />
        <CaseStudySolution
          isReverse
          isImageSmall
          isTextWhite
          image={solutionDeliveredSix}
          title="Quoting system"
          description={[
            'One more helpful function we created in terms of this SaaS platform development is a quote request form. By filling out this form, users can describe what they want, what the cake’s design is, for what event, and other essential parameters to the order.',
            <div className="mb-2" key="descr" />,
            'In turn, cake makers can accept or decline the request and provide a quote with price and sketches, etc.',
            <div className="mb-2" key="descrNext" />,
            'Moreover, we implemented a messaging system, so customers and cake makers can agree on order details even simpler.',
          ]}
          alt="Quoting and messaging systems for the custom bakery website | Codica"
          imageTitle="Quoting system for the custom bakery website | Codica"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredSeven}
          title="Different subscription plans"
          description={[
            'Also, we created various subscriptions plan for users. For instance, when new users come to the platform, they can use a free trial period to test the solution in practice. Moreover, they can pick specific subscription options that help users save a few months’ subscription fees.',
            <div className="mb-2" key="descr" />,
            'Another feature we developed for the project launch stage is a subscription via promo code for $1. This variant enables using this SaaS solution for bakery businesses for one year freely.',
            <div className="mb-2" key="descrNext" />,
            'By the way, users can shift between subscription plans according to their needs.',
          ]}
          alt="Different subscription plans for the custom bakery website | Codica"
          imageTitle="Various subscription plans for the custom bakery website | Codica"
        />
      </div>

      <div className={containerStyles.caseStudyVideo}>
        <LazyHydrate whenVisible>
          <CaseStudyVideoCase
            title="How it works"
            description="This video shows you how this works in real life. With the CakerHQ solution, customers can quickly and easily place an order by choosing the needed location. Within this platform, users can be confident in the security of their payments."
            ringsClass={containerStyles.caseStudyVideo__ring}
            image={videoCaseImage}
            videoLink="https://www.youtube.com/embed/QoqvP6NZNwY"
          />
        </LazyHydrate>
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudyTech
          technologies={[
            { label: 'React JS', icon: 'react' },
            { label: 'Ruby on Rails', icon: 'ror' },
            { label: 'AWS', icon: 'aws' },
            { label: 'Next.js', icon: 'nextjs' },
          ]}
          integrations={[
            { label: 'Stripe', icon: 'stripe' },
            { label: 'Google Cloud', icon: 'googleCloud' },
            { label: 'Twilio', icon: 'twilio' },
            { label: 'Sendgrid', icon: 'sendgrid' },
            { label: 'Sentry', icon: 'sentry' },
          ]}
        />
      </div>

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          isBottom
          quote={[
            {
              text: '“Nowadays, CakerHQ is live and operational in Australia and is helping both cake business owners with their administration and their clients with having a simple way of searching and booking their custom bakes. We have plans to expand CakerHQ’s features and offerings in the future, and I look forward to bringing these to life with the Codica team.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/clients/client-caker.png"
              alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
              title={`${quotes.client.name}, ${quotes.client.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.client.name}
          position={quotes.client.position}
        />
      </div>

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s turn your idea into a successful product!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewVariaTitle}
              color="variaColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/varia.png"
                  alt={`Case study: ${previewVariaTitle} | Codica`}
                  title={`Case study: ${previewVariaTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                />
              }
              url="saas-real-estate-platform"
            />
            <CaseStudyPreview
              title={previewImpactTitle}
              color="impactColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/impact.png"
                  alt={`Case study: ${previewImpactTitle} | Codica`}
                  title={`Case study: ${previewImpactTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="fitness-progressive-web-application"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesCakerHQ
